import './Footer.css'

function Footer(){
    return(
        <div className='footer'> 
        <p>@ 2024 Project. All rights reserved.</p>
        <ul>
            <li>Terms of Services</li>
            <li>Privacy Policy</li>
        </ul>

        </div>
    )
}

export default Footer;